body,
p {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* react-toastify */
.Toastify__toast-container {
  max-width: 400px;
}

.Toastify__toast {
  min-height: 36px;
}

.Toastify__toast-body {
  padding: 8px;
  font-size: 12px;
}

.smooth-dnd-container {
  min-height: 300px !important;
}

/* Custom CSS for Chatwoot Widget */
@media only screen and (min-width: 667px) {
  .woot-widget-holder {
    bottom: 116px !important;
  }

  .woot-widget-holder.woot-elements--left {
    left: 25px !important;
  }
}

.woot-widget-bubble {
  bottom: 32px !important;
}

.woot-widget-bubble.woot-elements--left {
  left: 25px !important;
}
