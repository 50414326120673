.ag-theme-quartz {
  --ag-font-size: 0.75rem;
  --ag-font-family: roboto, sans-serif;
  /* header */
  --ag-header-height: 40px;
  /* checkbox */
  --ag-checkbox-checked-color: #13a89e;
  --ag-checkbox-unchecked-color: #263238;
  --ag-checkbox-indeterminate-color: #13a89e;
  --ag-icon-size: 18px;
  --ag-line-height: 1.43;

  --ag-cell-horizontal-border: var(--ag-row-border-width)
    var(--ag-row-border-style) var(--ag-row-border-color); /* Add left and right borders to each cell and use row border's properties  */
  --ag-header-column-separator-display: block;
}

.ag-cell {
  display: flex;
  align-items: center;
}
