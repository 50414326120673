.react-calendar {
  border-radius: 8px;
  border-color: #13a89e;
  font-family: Roboto;
  color: #263238;
  overflow: hidden;
}

.react-calendar__tile--now {
  background: #ff9800;
  font-weight: bold;
  color: #fff;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ff9800;
  font-weight: bold;
  color: #fff;
}
